<template>
  <div class="video-box" :style="`background-image: url(${bg});`">
    <svg
      @click="player = true"
      id="playBtn"
      width="120"
      height="88"
      viewBox="0 0 120 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="playWrapper"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M120 0H0V78H5H10V83V88H120V0Z"
        fill="#3EADE2"
      />
      <path
        id="play"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M53 22H43V67H53V62H63V57H73V52H78V47H83V42H78V37H73V32H63V27H53V22Z"
        fill="#F5F5F5"
      />
    </svg>
    <div v-if="player === true" class="video-overlay">
      <div class="trailer fade-in-fwd">
        <div @click="exitPlayer()" class="close-btn">
          <p>&times;</p>
        </div>
        <iframe
          width="100%"
          height="100%"
          :src="url"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoBox",
  props: {
    url: String,
    bg: ''
  },
  data: () => ({
    player: false,
  }),
  methods: {
    exitPlayer() {
      this.player = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/variables";

.video-box {
  width: 100%;
  height: 440px;
  background-color: $darkCoal;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    cursor: pointer;
  }
}
#playBtn {
  cursor: pointer;
  &:hover > #play {
    transition: 0.25s ease;
    fill: $hover;
  }
}
.video-overlay {
  position: fixed;
  z-index: 12;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  background-color: rgba(0, 0, 0, 0.886);
  display: flex;
  align-items: center;
  justify-content: center;
  .trailer {
    border: 3px solid rgba(255, 255, 255, 0.468);
    position: relative;
    z-index: 13;
    width: 800px;
    height: 450px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.659);
    .close-btn {
      padding: 0;
      margin: 0;
      position: absolute;
      right: -5%;
      top: -10%;
      font-size: 4rem;
      font-weight: bold;
      color: #fff;
      cursor: pointer;
      background-color: rgba(255, 0, 0, 0.529);
      height: 40px;
      width: 40px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.25s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
      p {
        padding: 0;
        margin: 0;
      }
    }
  }
}
</style>
