<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="swiper-vue">
          <carousel :perPage="6" :paginationEnabled="false" :perPageCustom="[[300, 1], [500, 2], [768, 3], [1024, 4], [1240, 6]]" ref="slider" class="mb-2">
            <slide v-for="(slide, idx) in slides" :key="idx">
              <a :href="slide.url" target="_blank">
                <div
                  class="d-flex align-items-center justify-content-center slide-box"
                >
                  <img :src="slide.img" :alt="slide.title" />
                </div>
              </a>
              <button class="text-center button-full">
                <a :href="slide.url" target="_blank">{{slide.title}}</a>
              </button>
            </slide>
          </carousel>
          <div
            class="buttonset d-flex lign-items-center justify-content-between mb-3"
          >
            <svg
              id="prevBtn"
              @click="prev()"
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="arrw_back_rel">
                <path
                  id="Combined Shape"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M35 20H30H25H20V25H25H30V30V35V40V45H25H20V50H25H30H35V45V40H40H45H50V35V30V25V20H45V25V30H40H35V25V20ZM15 40V45H20V40H15ZM15 30V35V40H10H5V35V30H0V25V20H5V25V30H10H15ZM15 30V25H20V30H15Z"
                  fill="black"
                />
                <path
                  id="prevColorOne"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M30 5H25H20V10H25H30V5ZM15 15H20V10H15V15ZM10 20H15V15H10V20ZM10 20H5V25H10V20ZM40 15H45V20H40H35H30V15H35H40Z"
                  fill="#83E4FF"
                />
                <path
                  id="prevColorTwo"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M30 10H25H20V15H15V20H10V25H5V30H10V35H15V40H20V45H25H30V40V35H35H40H45V30V25V20H40H35H30V15V10Z"
                  fill="#007FBB"
                />
              </g>
            </svg>

            <svg
              id="nextBtn"
              @click="next()"
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="arrw_nxt_REL">
                <path
                  id="Combined Shape"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15 20H20H25H30V25H25H20V30V35V40V45H25H30V50H25H20H15V45V40H10H5H0V35V30V25V20H5V25V30H10H15V25V20ZM35 40V45H30V40H35ZM35 30V35V40H40H45V35V30H50V25V20H45V25V30H40H35ZM35 30V25H30V30H35Z"
                  fill="black"
                />
                <path
                  id="nextColorOne"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20 5H25H30V10H25H20V5ZM35 15H30V10H35V15ZM40 20H35V15H40V20ZM40 20H45V25H40V20ZM10 15H5V20H10H15H20V15H15H10Z"
                  fill="#83E4FF"
                />
                <path
                  id="nextColorTwo"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20 10H25H30V15H35V20H40V25H45V30H40V35H35V40H30V45H25H20V40V35H15H10H5V30V25V20H10H15H20V15V10Z"
                  fill="#007FBB"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Slider",
  props: {
    slides: [],
  },
  methods: {
    next() {
      this.$refs.slider.handleNavigation();
    },
    prev() {
      this.$refs.slider.handleNavigation("backward");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/_variables";
.slide-box {
  border: 5px solid $darkBlue;
  padding: 55px 0;
  height: 220px;
  img {
    max-width: 120px;
  }
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
}
.button-full {
  padding: 16px 0;
  width: 100%;
  color: $linkColor;
  background-color: $darkBlue;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: $linkColor;
  a {
    color: $linkColor;
    text-decoration: none;
  }
}

svg {
  cursor: pointer;
}

#prevColorTwo:hover {
  fill: #3eade2;
}
#nextColorTwo:hover {
  fill: #3eade2;
}
#prevColorTwo:active {
  fill: $hover;
}
#nextColorTwo:active {
  fill: $hover;
}
</style>
