<template>
  <footer class="bg-dark-orange pt-5 pb-4">
    <div class="container">
      <div class="row">
        <div class="col-md-3" v-for="(menuItem, idx) in footerMenu" :key="idx">
          <div id="footerLogo" v-if="menuItem.logo">
            <img :src="`/img/${menuItem.logo}`" alt="Footer Logo" />
            <hr />
            <h4 class="text-uppercase">
              {{ menuItem.alt }}
            </h4>
            <div
              class="d-flex align-items-center justify-content-start gap-3 d-none d-md-flex"
              id="footerSocialLg"
            >
              <a
                class="social-icon"
                v-for="(icon, idx) in socialIcons"
                :key="idx"
                target="_blank"
                :href="icon.url"
              >
                <img
                  style="width: initial; margin-bottom: 0; max-width: 30px;"
                  :src="`/img/icons/social/${icon.img}.svg`"
                  :alt="icon.alt"
                />
              </a>
            </div>
          </div>
          <div v-else class="f-menu">
            <div class="footer-heading">
              {{ menuItem.title }}
            </div>
            <hr />
            <div
              class="footer-link"
              v-for="(link, index) in menuItem.links"
              :key="index"
            >
              <router-link v-if="link.local === true" :to="link.url">{{
                link.title
              }}</router-link>
              <a v-else
                :href="link.url"
                :target="link.target ? link.target : '_self'"
                >{{ link.title }}</a
              >
            </div>
          </div>
        </div>
      </div>

      <div class="row d-flex d-md-none">
        <div
          class="col-md-12 d-flex align-items-center justify-content-between mt-5"
        >
          <a
            class="social-icon"
            v-for="(icon, idx) in socialIcons"
            :key="idx"
            target="_blank"
            :href="icon.url"
          >
            <img style="max-width: 30px;" :src="`/img/icons/social/${icon.img}.svg`" :alt="icon.alt" />
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <hr />
          <p>
            {{ new Date().getFullYear() }} NFTIZ All Rights Reserved
            <span class="yellow-text"> | </span> <a href="#">Terms Of Use</a>
            <span class="yellow-text"> | </span> <a href="#">Privacy Policy</a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data: () => ({
    footerMenu: [
      {
        logo: "logo.svg",
        alt: "Promote to earn platform",
      },
      {
        title: "COMPANY",
        links: [


          {
            url: "https://whitepaper.nftiz.com",
            title: "Whitepaper",
            local: false,
            target: "_blank",
          },
          {
            url: "https://cdn.nftiz.com/deck.pdf",
            title: "Pitch Deck",
            local: false,
            target: "_blank",
          },
          {
            url: "https://cdn.nftiz.com/onepager.pdf",
            title: "Onepager",
            local: false,
            target: "_blank",
          },
          {
            url: "https://cdn.nftiz.com/memo.pdf",
            title: "Memo",
            local: false,
            target: "_blank",
          },
          {
            url: "https://airtable.com/shrWroDBJRqhJeekx", //https://nftiz.com/jobs",
            title: "Careers",
            local: false,
            target: "_blank",
          },

          {
            url: "https://blog.nftiz.com",
            title: "Blog",
            local: false,
            target: "_blank",
          },
          {
            url: "https://airtable.com/shrwS6xcEV13rnRFF",
            title: "Contact Form",
            local: false,
            target: "_blank",
          },
        ],
      },
      {
        title: "ADVERTISERS",
        links: [
          {
            url: "https://docs.nftiz.com/advertiser/start",
            title: "How to start",
            target: '_blank',
            local: false,
          },

          {
            url: "https://docs.nftiz.com/advertiser/faq",
            title: "FAQ",
            target: '_blank',
            local: false,
          },
          {
            url: "https://docs.nftiz.com/advertiser/",
            title: "Docs",
            target: '_blank',
            local: false,
          },
          {
            url: "https://cdn.nftiz.com/products/nft.pdf",
            title: "Sell NFT",
            target: '_blank',
            local: false,
          },
          {
            url: "https://cdn.nftiz.com/products/token.pdf",
            title: "Sell Tokens",
            target: '_blank',
            local: false,
          },
          {
            url: "https://cdn.nftiz.com/products/users.pdf",
            title: "Get Users",
            target: '_blank',
            local: false,
          },
        ],
      },
      {
        title: "PUBLISHERS",
        links: [
          {
            url: "https://docs.nftiz.com/publisher/start",
            title: "How to start",
            target: '_blank',
            local: false,
          },
          {
            url: "https://docs.nftiz.com/publisher/faq",
            title: "FAQ",
            local: false,
            target: '_blank',
          },
          {
            url: "https://docs.nftiz.com/publisher/",
            title: "Docs",
            target: '_blank',
            local: false,
          },
          {
            url: "https://nftiz.com/publisher/marketplace",
            title: "Marketplace",
            local: false,
          },
          {
            url: "https://discord.nftiz.com/nftiz?utm_source=lander",
            title: "Join our Discord Community",
            target:'_blank',
            local: false,
          },

        ],
      },
    ],
    socialIcons: [
      {
        img: "youtube",
        alt: "YouTube",
        url: "https://www.youtube.com/@nftiz",
      },
      {
        img: "twitter",
        alt: "Twitter",
        url: "https://twitter.com/nftiznetwork",
      },
      {
        img: "linkedin",
        alt: "Linkedin",
        url: "https://www.linkedin.com/company/nftiz-launchpad/",
      },
      {
        img: "discord",
        alt: "Discord",
        url: "https://discord.nftiz.com/nftiz?utm_source=lander",
      },
      {
        img: "blog",
        alt: "Blog",
        url: "https://blog.nftiz.com",
      },
      {
        img: "chat",
        alt: "Open ticket",
        url: "https://airtable.com/shrwS6xcEV13rnRFF/",
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
@import "../assets/_variables.scss";
footer {
  #footerLogo {
    margin-top: 7px;
    img {
      width: 60%;
      margin-bottom: 10px;
    }
    #footerSocialLg {
      img {
        width: 100%;
      }
    }
  }
  hr {
    border-top: 2px solid $white;
  }
  .text-uppercase {
    font-size: 1.5rem;
    color: $linkColor;
    letter-spacing: 0;
    line-height: 34px;
  }
  .footer-heading {
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 50px;
    color: $linkColor;
    @media screen and (max-width: $md) {
      font-size: 25px;
      line-height: 30px;
      padding-top: 2rem;
    }
  }
  .footer-link a {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    text-decoration: none;
    transition: 0.25s ease;
    color: $linkColor;
    &:hover {
      color: $hover;
    }
  }
  p,
  p a {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    color: $linkColor;
  }
  @media screen and (max-width: 768px) {
    p a {
      display: none;
    }
    p span {
      display: none;
    }
  }
}
.social-icon {
  width: 30px;
  height: 30px;
  padding: 10px;
  background-color: $white;
  cursor: pointer;
  box-shadow: 10px 10px 0px 0px $black20;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
