<template>
  <nav>
    <div
      class="logo"
      @click="$route.path !== '/' ? $router.push({ name: 'home' }) : null"
    ></div>
    <ul class="menu d-none d-md-flex">
      <li v-for="(item, idx) in menu" :key="idx">
        <router-link :to="item.link">{{ item.text }}</router-link>
      </li>
    </ul>
    <svg
      style="cursor: pointer"
      @click="mobileMenu = !mobileMenu"
      class="d-block d-md-none"
      width="40px"
      height="40px"
      viewBox="0 0 23 23"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="NFTIZ_HOME_Responsive_Extra-Small"
          transform="translate(-282.000000, -20.000000)"
        >
          <g id="mnu_button" transform="translate(282.000000, 20.000000)">
            <rect
              id="Rectangle"
              fill="#000000"
              opacity="0.2"
              x="3"
              y="3"
              width="20"
              height="20"
            ></rect>
            <rect
              id="Rectangle-Copy"
              fill="#F5F5F5"
              x="0"
              y="0"
              width="20"
              height="20"
            ></rect>
            <path
              d="M3,5 L17,5 L17,7 L3,7 Z M3,9 L17,9 L17,11 L3,11 Z M3,13 L17,13 L17,15 L3,15 Z"
              id="Combined-Shape"
              fill="#E63B16"
            ></path>
          </g>
        </g>
      </g>
    </svg>
    <ul v-if="mobileMenu" class="mobile-menu">
      <li></li>
    </ul>
    <div @click="socialMenu = !socialMenu" class="box-top d-none d-md-block">
      <div
        class="box-top-inside"
        :class="{ 'top-box-pressed': socialMenu }"
      ></div>
    </div>
    <div class="social-icons fade-in-right" v-if="socialMenu === true">
      <div v-for="(icon, idx) in socialIcons" :key="idx" class="social-icon">
        <a :href="icon.url" target="_blank">
          <img
            style="max-width: 30px"
            :src="`/img/icons/social/${icon.img}.svg`"
            :alt="icon.alt"
            @click="socialMenu = false"
          />
        </a>
      </div>
    </div>
    <MobMenu v-if="mobileMenu" class="bg-dark-orange white-text" />
  </nav>
</template>

<script>
export default {
  name: "NavBar",
  data: () => ({
    mobileMenu: false,
    socialMenu: false,
    socialIcons: [
      {
        img: "youtube",
        alt: "YouTube",
        url: "https://www.youtube.com/@nftiz",
      },
      {
        img: "twitter",
        alt: "Twitter",
        url: "https://twitter.com/nftiznetwork",
      },
      {
        img: "linkedin",
        alt: "Linkedin",
        url: "https://www.linkedin.com/company/nftiz-launchpad/",
      },
      {
        img: "discord",
        alt: "Discord",
        url: "https://discord.nftiz.com/nftiz?utm_source=lander",
      },
      {
        img: "blog",
        alt: "Blog",
        url: "https://blog.nftiz.com?utm_source=lander",
        class: 'sm-icon'
      },
      {
        img: "chat",
        alt: "Open ticket",
        url: "https://airtable.com/shrwS6xcEV13rnRFF/",
      },
    ],
    menu: [
      {
        text: "Advertiser",
        link: "/advertiser",
      },
      {
        text: "Publisher",
        link: "/publisher",
      },
      {
        text: "Login",
        link: "/login",
      },
    ],
  }),
  watch: {
    $route() {
      this.mobileMenu = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/_variables";
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

$logo: url("../assets/logo.svg");
$mobLogo: url("../assets/mob-logo.svg");
$logoHover: url("../assets/logo_hover.svg");
$logoPressed: url("../assets/logo_pressed.svg");
nav {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: transparent !important;
  height: 70px;
  width: 100%;
  svg {
    position: absolute;
    right: 12px;
  }
}
.logo {
  width: 200px;
  height: 100%;
  background-image: $logo;
  background-position: center center;
  background-repeat: no-repeat;

  cursor: pointer;
  @media screen and (max-width: 768px) {
    background-size: 40px;
    background-position: left center;
    background-image: $mobLogo;
    margin-left: 12px;
  }

  &:hover {
    background-image: $logoHover;
    @media screen and (max-width: 768px) {
      background-image: $mobLogo;
    }
  }
  &:active {
    @media screen and (max-width: 768px) {
      background-image: $mobLogo;
    }
    background-image: $logoPressed;
  }
  .router-link-exact-active {
    @media screen and (max-width: 768px) {
      background-image: $mobLogo;
    }
    background-image: $logoPressed;
  }
}
.menu {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  .router-link-exact-active,
  .router-link-active {
    color: $hover;
  }
  a {
    text-decoration: none;
    text-transform: uppercase;
    color: $linkColor;
    transition: 0.25s ease-in-out;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    &:hover {
      color: $hover;
    }
    &:active {
      color: $yellow;
    }
  }
}
.box-top {
  position: absolute;
  top: 10px;
  right: 30px;
  width: 30px;
  height: 30px;
  padding: 10px;
  background-color: $white;
  cursor: pointer;
  box-shadow: 10px 10px 0px 0px $black20;
  &:hover > .box-top-inside {
    background-color: $hover;
  }
  .box-top-inside {
    width: 10px;
    height: 10px;
    background-color: $darkOrange;
  }
  .top-box-pressed {
    background-color: $hover;
  }
}

.social-icons {
  position: absolute;
  // width: 100%;
  // height: auto;
  display: flex;
  right: 70px;
  gap: 10px;
  top: 10px;
  align-items: center;
  justify-content: center;
}

.social-icon {
  width: 30px;
  height: 30px;
  padding: 10px;
  background-color: $white;
  cursor: pointer;
  box-shadow: 10px 10px 0px 0px $black20;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.fade-in-right {
  animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes fade-in-right {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.fade-out-right {
  animation: fade-out-right 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes fade-out-right {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(50px);
    opacity: 0;
  }
}
</style>
