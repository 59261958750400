<template>
  <div :class="`text-${align}`">
    <h2>{{ text }}</h2>
  </div>
</template>

<script>
export default {
  name: "LanderHeading",
  props: {
    text: {
      type: String,
      default: "NFTIZ",
    },
    align: String,
  },
};
</script>

<style scoped lang="scss">
@import "../assets/variables";

h2 {
  font-style: normal;
  font-weight: 900;
  font-size: 80px;
  line-height: 80px;
  text-transform: uppercase;
  color: $linkColor;
  @media screen and (max-width: $md) {
    font-size: 45px;
    line-height: 45px;
  }
}
</style>
