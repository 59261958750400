<template>
  <div class="benefits row">
    <div class="col-md-4" v-for="(benefit, idx) in items" :key="idx">
      <div class="benefit-box" :class="`bg-${bg}`">
        <img
          :src="`/img/benefits/${benefit.img}.svg`"
          :id="`benImg${idx + 1}`"
          :alt="benefit.title"
        />
      </div>
      <h4 class="benefit-title" v-html="benefit.title"></h4>
      <hr />
      <p
        class="benefit-info"
        :class="`${pColor}-text`"
        v-html="benefit.info"
      ></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "LanderBenefits",
  props: {
    items: [],
    bg: {
      type: String,
      default: "blue",
    },
    pColor: {
      type: String,
      default: "link",
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/variables";
.benefit-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 350px;
  max-width: 100%;
  border: 5px solid $linkColor;
  padding: 50px;

  img {
    max-width: 65%;
  }
}
h4 {
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 47px;
  text-transform: uppercase;
  color: $dark;
  margin-top: 20px;
  @media screen and (max-width: $md) {
    font-size: 30px;
    line-height: 30px;
  }
}
hr {
  border-top: 2px solid $white;
}
p {
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 80px;
  @media screen and (max-width: $md) {
    font-size: 22px;
    line-height: 26px;
    text-align: justify;
  }
}
</style>
