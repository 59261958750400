<template>
  <div class="button-full" :class="`bg-${bg}`">
    <router-link v-if="!target" :to="url ? url : ''" :class="`${color}-text`"
      >{{ title }}
      <svg
        width="15"
        height="25"
        viewBox="0 0 15 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            id="arrowRightSvg"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5 0H0V5H5V10H10V15H5V20H0V25H5V20H10V15H15V10H10V5H5V0Z"
            :fill="fill"
          />
        </g>
      </svg> </router-link
    >
    <a v-else target="_blank" :href="url ? url : ''" :class="`${color}-text`"
      >{{ title }}
      <svg
        width="15"
        height="25"
        viewBox="0 0 15 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            id="arrowRightSvg"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5 0H0V5H5V10H10V15H5V20H0V25H5V20H10V15H15V10H10V5H5V0Z"
            :fill="fill"
          />
        </g>
      </svg> </a
    >
  </div>
</template>

<script>
export default {
  name: "LanderCta",
  props: {
    color: {
      type: String,
      default: 'white'
    },
    bg: {
      type: String,
      default: "blue",
    },
    title: {
      type: String,
      default: "Get Started",
    },
    url: {
      type: String,
      // default: "/login",
    },
    fill: {
      type: String,
      default: '#F5F5F5'
    },
    target: String
  },
  data: () => ({}),
};
</script>

<style scoped lang="scss">
@import "../assets/_variables";

.button-full {
  padding: 30px 0 25px;
  text-align: center;
  box-shadow: 0px 10px 0 0 $black20;
  &:hover > svg > #arrowRightSvg {
    fill: $hover;
  }
  a {
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 20px;
    transition: 0.25s ease;
    &:hover {
      color: $hover;
    }
  }
  svg {
    margin-left: 1rem;
  }
}
</style>
