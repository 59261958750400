<template>
  <div class="jumbo-wrapper" :class="{ 'jumbo-m': sub }">
    <h1 class="jumbo">
      <slot name="jumboText">NFTIZ</slot>
    </h1>
    <h3 :class="sub ? 'sub' : 'nosub'">
      <slot name="h3text">web3 Affiliate Platform</slot>
    </h3>
    <p class="d-block d-md-none" v-if="sub === true">
      <slot name="sub"></slot>
    </p>
    <p class="d-none d-md-block" style="max-width: 65%" v-if="sub === true">
      <slot name="sub"></slot>
    </p>
    <button class="main-button" :class="{ 'mt-5': !sub }" @click="ctaAction()">
      <slot name="btnText">Get started</slot>
    </button>
    <div
      class="graphics text-center d-none d-md-flex flex-column align-items-center justify-content-between"
    >
      <img src="/img/moon_cut.svg" alt="Moon" class="moon d-none d-lg-block" />
      <img
        :src="rocket ? rocket : '/img/rocket_top.gif'"
        alt="Rocket"
        class="rocket d-none d-lg-block"
      />
    </div>
    <img
      src="/img/moon_cut.svg"
      alt="Moon"
      class="moon-m d-sm-block d-md-none"
    />
    <img
      :src="rocket ? rocket : '/img/rocket_top.gif'"
      alt="Rocket"
      class="rocket-m d-md-none d-block"
    />
  </div>
</template>

<script>
export default {
  name: "Jumbo",
  props: {
    rocket: String,
    sub: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  methods: {
    ctaAction() {
      if (this.$route.name === "home")
        location.replace("https://nftiz.com/login");
      else {
        location.replace(`https://nftiz.com/login?role=${this.$route.name}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/_variables";

.jumbo-wrapper {
  margin-top: 4.375rem;
  margin-bottom: 4.375rem;
  padding: 155px 0;
  background-color: $linkColor;
  position: relative;
  z-index: 8;
  box-shadow: 0px 39px 0px -28px $black20;
  @media screen and (max-height: $md) {
    display: flex;
    flex-direction: column;
  }
  // height: 960px;
  h1 {
    margin-left: 15px;
    font-style: normal;
    font-weight: 900;
    font-size: 80px;
    line-height: 80px;
    text-transform: uppercase;
  }
  h3.nosub {
    margin-left: 15px;
    margin-bottom: 100px;
    margin-top: 30px;
    color: $dark;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 35px;
  }
  h3.sub {
    // margin: 30px 0 0 15px;
    margin-left: 15px;
    padding-right: 1rem;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 35px;
  }
  p {
    margin: 0px 0 80px 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    color: #999da2;
  }
  .main-button {
    // margin-top: 30px;
    background-image: url("../assets/arrow_right.svg");
    background-position: right center;
    background-repeat: no-repeat;
    background-origin: content-box;
    width: 460px;
    height: 80px; // add media queries
    padding: 30px 20px;
    cursor: pointer;
    background-color: $darkOrange;
    color: $linkColor;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 20px;
    text-align: center;
    box-shadow: 10px 10px 0px 0px $black20;
    transition: 0.25s ease;
    &:hover {
      background-image: url("../assets/arrow_right_hover.svg");
    }
    &:active {
      color: $yellow;
    }
  }
  .mtb {
    margin-top: 150px;
  }

  .graphics {
    position: absolute;
    top: 0;
    right: 12%;
    height: 100%;
    .rocket {
      margin-bottom: 50%;
    }
  }

  // .moon {
  //   position: absolute;
  //   top: 0;
  //   right: 8%;
  // }

  .moon-m {
    position: absolute;
    left: 50%;
    top: 0;
    width: 60%;
    transform: translateX(-50%);
  }

  .rocket-m {
    width: 20%;
    margin: auto;
    padding-top: 10%;
  }

  #rocketHome {
    padding-top: 25%;
    @media screen and (max-width: $md) {
      padding-top: 0;
    }
  }

  @media screen and (max-width: 1240px) {
    padding: 2rem 0;
    @media screen and (max-width: $md) {
      height: 85vh;
    }
    h1 {
      font-size: 60px;
      line-height: 60px;
      @media screen and (max-width: $md) {
        padding-top: 40px;
        padding-right: 10px;
        font-size: calc(2.3rem + 1.5vw);
        line-height: 1.2;
        max-width: 90%;
      }
    }

    h3.nosub,
    h3.sub {
      margin-bottom: 0;      
      margin-left: 15px;
      line-height: 1.2;
      font-size: calc(1.7rem - 0.2vw); // check right margin
      font-weight: 400;
      max-width: 90%;
    }

    p {
      margin-top: 15px;
      margin-bottom: 0;
      font-size: 16px;
      line-height: 16px;
      @media screen and (max-width: $md) {
        font-size: calc(1.2rem + 0.5vw);
        line-height: 1.2;
        padding-right: 1rem;
      }
    }
    .main-button {
      font-size: 25px;
      line-height: 15px;
      width: 350px;
      padding: 25px 15px;
      @media screen and (max-width: 768px) {
        width: 95%;
        margin-top: 30px;
        padding: 15px 15px;
        line-height: 18px;
      }
    }
    .rocket {
      transform: scale(0.85);
    }
    .moon {
      display: none;
    }
  }
}
.jumbo-m {
  @media screen and (max-height: 900px) {
    height: auto;
    min-height: 85vh;
  }
}
.centered-absolute {
  // width: 90px;
  // height: 65px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
