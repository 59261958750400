import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.min.js'
import './assets/app.scss'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel)

Vue.config.productionTip = false

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)

Vue.use(VueScrollTo, {
     container: "body",
     duration: 300,
     easing: "ease-in-out",
     offset: 0,
     force: true,
     cancelable: true,
     onStart: false,
     onDone: false,
     onCancel: false,
     x: false,
     y: true
 })

// global components registration
Vue.component('NavBar', require('./components/NavBar.vue').default)
Vue.component('MobMenu', require('./components/MobMenu.vue').default)
Vue.component('Slider', require('./components/Slider.vue').default)
Vue.component('Footer', require('./components/Footer.vue').default)
Vue.component('Jumbo', require('./components/Jumbo.vue').default)
Vue.component('LanderCta', require('./components/LanderCta.vue').default)
Vue.component('LanderHeading', require('./components/LanderHeading.vue').default)
Vue.component('ChevronHeading', require('./components/ChevronHeading.vue').default)
Vue.component('LanderBenefits', require('./components/LanderBenefits.vue').default)
Vue.component('VideoBox', require('./components/VideoBox.vue').default)
Vue.component('FAQ', require('./components/FAQ.vue').default)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
