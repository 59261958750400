<template>
  <div class="accordion accordion-flush" id="faq">
    <div class="accordion-item" v-for="(q, idx) in faq" :key="idx">
      <h2 class="accordion-header" :id="`flush-heading${[idx]}`">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="`#flush-collapse${[idx]}`"
          aria-expanded="false"
          :aria-controls="`flush-collapse${[idx]}`"
        >
          {{ q.question }}
        </button>
      </h2>
      <div
        :id="`flush-collapse${[idx]}`"
        class="accordion-collapse collapse"
        :aria-labelledby="`flush-heading${[idx]}`"
        data-bs-parent="#accordionFlushExample"
      >
        <div class="accordion-body" v-html="q.answer">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQ",
  props: {
    faq: [],
  },
};
</script>

<style scoped lang="scss">
@import "../assets/variables";

#faq {
  color: #fff;
  background-color: $darkCoal;
  .accordion-item {
    border: none;
    margin-bottom: 5px;
  }
  .accordion-item,
  .accordion-header,
  .accordion-button {
    background-color: $coal;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
    color: $linkColor;
    @media screen and (max-width: $md) {
      font-size: 20px;
      line-height: 24px;
    }
  }
  .accordion-button::after {
    background-image: url("../assets/arrow_right.svg");
    background-size: contain;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url("../assets/arrow_right.svg");
    transform: rotate(90deg);
    box-shadow: none;
  }
  .accordion-button:focus {
    outline: none;
    box-shadow: none;
    border-color: $hover;
  }
  .accordion-body {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    color: #999da2;
    @media screen and (max-width: $md) {
      font-size: 22px;
      line-height: 27px;
    }
  }
}
</style>
