import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/advertiser",
    name: "advertiser",
    component: () => import("../views/Advertiser.vue"),
  },
  {
    path: "/publisher",
    name: "publisher",
    component: () => import("../views/Publisher.vue"),
  },
  {
    path: "/login",
    beforeEnter(to, from, next) {
      if (to.query.role) {
        location.href = `https://nftiz.com/login?role=${to.query.role}`;
      } else {
        location.href = "https://nftiz.com/login";
      }
    },
  },
  // {
  //   path: "/docs",
  //   beforeEnter(to, from, next) {
  //     location.href = "https://docs.nftiz.com";
  //   },
  // },
  // {
  //   path: "/deck",
  //   beforeEnter(to, from, next) {
  //     location.href = "https://cdn.nftiz.com/deck.pdf";
  //   },
  // },
  // {
  //   path: "/memo",
  //   beforeEnter(to, from, next) {
  //     location.href = "https://cdn.nftiz.com/memo.pdf";
  //   },
  // },
  // {
  //   path: "/whitepaper",
  //   beforeEnter(to, from, next) {
  //     location.href = "https://whitepaper.nftiz.com/";
  //   },
  // },
  // {
  //   path: "/onepager",
  //   beforeEnter(to, from, next) {
  //     location.href = "https://cdn.nftiz.com/onepager.pdf";
  //   },
  // },
  {
    path: "/demo",
    beforeEnter(to, from, next) {
      location.href = "https://cal.com/nftiz";
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, sp) {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
