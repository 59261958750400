<template>
  <div :class="`text-${align}`">
    <h2>{{text}}</h2>
    <img v-if="chevron" :src="`/img/chevron-${chevronColor}.svg`" alt="Chevron" id="chevron" />
  </div>
</template>

<script>
export default {
  name: 'ChevronHeading',
  props: {
    text: {
        type: String,
        default: 'NFTIZ'
    },
    chevron: {
      type: Boolean,
      default: false
    },
    chevronColor: {
        type: String,
        default: 'white'
    },
    align: String
  }
}
</script>

<style scoped lang="scss">
@import '../assets/variables';

h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 70px;
    line-height: 80px;
    text-transform: uppercase;
    margin-bottom: 30px;
    margin-top: 60px;
    color: $linkColor;
    @media screen and (max-width: $md) {
    font-size: 45px;
    line-height: 45px;
  }
  }
  #chevron {
    margin-bottom: 50px;
  }
</style>