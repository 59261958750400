<template>
  <div class="m-menu-wrapper fade-in-right">
    <div
      class="m-menu-img d-flex align-items-center justify-content-between pt-4 px-3"
    >
      <router-link to="/">
        <img src="/img/logo_faded.svg" alt="Logo" />
      </router-link>
      <img
        class="close-btn"
        @click="$parent.mobileMenu = false"
        src="/img/mnu_close_btn.svg"
        alt="Close mob menu"
      />
    </div>
    <ul class="m-menu-items">
      <li v-for="(link, idx) in menu" :key="idx">
        <router-link
          v-if="!link.target"
          class="m-menu-item d-flex align-items-center justify-content-between p-2"
          :to="link.url"
        >
          {{ link.title }}
          <img
            :src="`/img/${link.svg}`"
            :alt="link.title"
            :class="link.svg.includes('download') ? 'download-link' : ''"
          />
        </router-link>
        <a
          v-else
          class="m-menu-item d-flex align-items-center justify-content-between p-2"
          :href="link.url"
          target="_blank"
        >
          {{ link.title }}
          <img
            :src="`/img/${link.svg}`"
            :alt="link.title"
            :class="link.svg.includes('download') ? 'download-link' : ''"
          />
        </a>
      </li>
    </ul>
    <div
      class="m-menu-social p-3 d-flex align-items-center justify-content-between"
    >
      <a
        class="social-icon"
        v-for="(icon, idx) in social"
        :key="idx"
        target="_blank"
        :href="icon.url"
      >
        <img :src="`/img/icons/social/${icon.img}.svg`" :class="icon.class ? icon.class : ''" :alt="icon.alt" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobMenu",
  computed: {
    social() {
      return this.$parent.socialIcons;
    },
  },
  data: () => ({
    menu: [
      {
        title: "Get Started",
        url: "https://nftiz.com/login",
        target: "_blank",
        svg: "arrow_right.svg",
      },
      {
        title: "Log In",
        url: "https://nftiz.com/login",
        target: "_blank",
        svg: "arrow_right.svg",
      },
      {
        title: "For Advertiser",
        url: "/advertiser",
        svg: "arrow_right.svg",
      },
      {
        title: "For Publisher",
        url: "/publisher",
        svg: "arrow_right.svg",
      },
      {
        title: "Docs",
        url: "https://docs.nftiz.com/publisher/",
        target: "_blank",
        svg: "arrow_right.svg",
      },
      {
        title: "Pitch Deck",
        url: "https://cdn.nftiz.com/deck.pdf",
        target: "_blank",
        svg: "download_w.svg",
      },
      {
        title: "Whitepaper",
        url: "https://whitepaper.nftiz.com/",
        target: "_blank",
        svg: "download_w.svg",
      },
      {
        title: "Onepager",
        url: "https://cdn.nftiz.com/onepager.pdf",
        target: "_blank",
        svg: "download_w.svg",
      },
      {
        title: "Memo",
        url: "https://cdn.nftiz.com/memo.pdf",
        target: "_blank",
        svg: "download_w.svg",
      },
    ],
  }),
};
</script>

<style scoped lang="scss">
@import "../assets/variables";
.router-link-active {
  color: $hover;
}
ul, li {
  list-style: none;
}
ul {
  padding: 10px;
}
.m-menu-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  .close-btn {
    cursor: pointer;
    width: 40px;
  }
  .m-menu-item {
    img {
      width: 12px;
    }
    margin-bottom: 5px;
    box-shadow: 0px 5px 7px -4px rgba(0, 0, 0, 0.5);
    .download-link {
      width: 18px;
    }
  }
  a {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 18px;
    text-decoration: none;
    color: $linkColor;
    transition: 0.25s ease;
    &:hover {
      color: $hover;
    }
  }
}
.m-menu-social {
  width: 100%;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
.social-icon {
  width: 30px;
  height: 30px;
  padding: 10px;
  background-color: $white;
  cursor: pointer;
  box-shadow: 10px 10px 0px 0px $black20;
  display: flex;
  align-items: center;
  justify-content: center;
  .sm-icon {
    max-width: 25px !important;
  }
}
.fade-in-right {
  animation: fade-in-right 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
}

@keyframes fade-in-right {
  0% {
    transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    transform-origin: 0% 50%;
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    transform: translateX(0) scaleY(1) scaleX(1);
    transform-origin: 50% 50%;
    filter: blur(0);
    opacity: 1;
  }
}
</style>
